import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CONTACT_US, PRIVACY_POLICY} from "../Messages/constantMessages";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import {removeQuery} from "./utils/func";


const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        background: '#afeeee',
        marginTop: 'auto'
    },
    links: {
        marginTop: '1rem',
        textAlign: "center"
    },
    menu: {
        textAlign: "center",
        margin: 0,
        padding: theme.spacing(1)
    },

    menuItem: {
        display: "inline",
        marginLeft: theme.spacing(8),
        textDecoration: 'none',
        fontSize: '20px',
    },
    menuItemMd: {
        display: "inline",
        marginLeft: theme.spacing(2),
        textDecoration: 'none',
    },
    href: {
        // textDecoration: 'none',
        color: "blue",
        // "&:hover": {
        //     textDecoration: 'underline',
        // },
        [theme.breakpoints.only('sm')]: {
            whiteSpace: 'pre-wrap',
        },
    },
    menuXs: {
        listStyle: 'none',
        textAlign: 'left',
        padding: '0',
        whiteSpace: 'pre-wrap',
        display: 'inline-block',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '7px',
    },
    menuItemXS: {
        margin: '5px'
    },
    footerImage: {
        width: '75px',
        height: '100px',
        // marginRight: theme.spacing(4),
        float: 'right',
        marginTop: '5px',
        marginRight: '7px',
    },
    footerLgContainer: {
        height: '100px',
        // backgroundColor: 'pink',
    },
    footerMenuLg: {
        marginLeft: '26px',
        height: '60px',
        display: 'inline-block',
        // backgroundColor: 'red',
    },
    footerUlLg: {
        padding: '0',
        marginTop: '38px',
    },
    menuItemLg: {
        display: "inline",
        marginLeft: '1rem',
        fontSize: '16px',
        border: '2px solid #afeeee',
        padding: '8px',
        borderRadius: '5%',
        '&:hover': {
            border: '2px solid',
        },
    },
    hrefLg: {
        textDecoration: 'none',
        color: 'black',
    },
    copyRight: {
        height: '60px',
        marginTop: '30px',
        // lineHeight: '60px',
        float: 'right',
        display: 'inline-block',
        // cursor: 'pointer',
        // backgroundColor: 'green',
    },
    copyRightP: {
        margin: '0',
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.54)',
        border: '2px solid #afeeee',
        padding: '8px',
        borderRadius: '5%',
        // '&:hover': {
        //     border: '2px solid rgba(0, 0, 0, 0.54)',
        // },
    },
    snsLinksLg: {
        height: '60px',
        display: 'inline-block',
        float: 'right',
        marginRight: '34px',
        marginLeft: '2rem',
        // backgroundColor: 'blue',
    },
    itemImage: {
        borderRadius: '50%',
        cursor: 'pointer',
        // pcサイズ
        // marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: '33px',
        width: '30px',
        height: '30px',
    },

}));
const Footer = () => {
    const classes = useStyles()

    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '_e'

    const footerImage = 'images/husky_apo.png'

    // url
    const contact = isEnglish ? '/contact_e' : '/contact'
    const howToUse = isEnglish ? '/how_to_use_e' : 'how_to_use'
    const privacyPolicy = isEnglish ? '/privacy_policy_e' : '/privacy_policy'
    const info = isEnglish ? '/info_e' : '/info'
    const costcoBlogUrl = isEnglish ? 'https://costco-johokan.com/costco-news/what-is-huskycart-e' : 'https://costco-johokan.com/costco-news/what-is-huskycart'


    // アイコン画像
    const insta_icon = 'images/sns_insta_icon.png'
    const facebook_icon = 'images/sns_facebook_sq.png'
    const twitter_icon = 'images/sns_twitter_sq.png'

    // リンク
    const insta_link = 'https://www.instagram.com/husky_cart'
    const facebook_link = 'https://www.facebook.com/huskycart'
    const twitter_link = 'https://twitter.com/husky_cart'

    return (
        <div className={classes.root}>

            {/*スマホサイズ*/}
            <Hidden lgUp>
                <ul className={classes.menuXs}>
                    <li className={classes.menuItemXS}>
                        <a href={contact} className={classes.href}>
                            {isEnglish ? CONTACT_US : 'お問い合わせ'}
                        </a>
                    </li>

                    <li className={classes.menuItemXS}>
                        <a href={costcoBlogUrl}
                           className={classes.href} target="_blank">
                            {isEnglish ? 'Usage guide' : '完全ガイド'}
                        </a>
                    </li>

                    <li className={classes.menuItemXS}>
                        <a href={privacyPolicy} className={classes.href}>
                            {isEnglish ? PRIVACY_POLICY : 'プライバシーポリシー'}
                        </a>
                    </li>

                    <li className={classes.menuItemXS}>
                        <a href={info} className={classes.href}>
                            {isEnglish ? 'About us' : '特商法に基づく表示'}
                        </a>
                    </li>
                </ul>
                {isEnglish ?
                    <a href="/e"><img src={footerImage} alt="footer_husky" className={classes.footerImage}/></a>
                    :
                    <a href="/"><img src={footerImage} alt="footerImageEng" className={classes.footerImage}/></a>

                }
            </Hidden>

            {/*PCサイズ*/}
            <Hidden mdDown>
                <div className={classes.footerLgContainer}>
                    {/*　footer menu　*/}
                    <div className={classes.footerMenuLg}>
                        <ul className={classes.footerUlLg}>
                            <li className={classes.menuItemLg}>
                                <a href={contact} className={classes.hrefLg}>
                                    {isEnglish ? CONTACT_US : 'お問い合わせ'}
                                </a>
                            </li>
                            <li className={classes.menuItemLg}>
                                <a href={costcoBlogUrl}
                                   className={classes.hrefLg} target="_blank">
                                    {isEnglish ? 'Usage guide' : '完全ガイド'}
                                </a>
                            </li>
                            <li className={classes.menuItemLg}>
                                <a href={privacyPolicy} className={classes.hrefLg}>
                                    {isEnglish ? PRIVACY_POLICY : 'プライバシーポリシー'}
                                </a>
                            </li>
                            <li className={classes.menuItemLg}>
                                <a href={info} className={classes.hrefLg}>
                                    {isEnglish ? 'About us' : '特商法に基づく表示'}
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* sns links */}
                    <div className={classes.snsLinksLg}>
                        <Box display="flex" justifyContent="center">
                            <Box>
                                <a href={insta_link}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img
                                        className={classes.itemImage}
                                        src={insta_icon}
                                        alt="insta_icon"/>
                                </a>
                            </Box>

                            <Box>
                                <a href={facebook_link}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img
                                        className={classes.itemImage}
                                        src={facebook_icon}
                                        alt="facebook_icon"/>
                                </a>
                            </Box>

                            <Box>
                                <a href={twitter_link}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img
                                        className={classes.itemImage}
                                        src={twitter_icon}
                                        alt="twitter"/>
                                </a>
                            </Box>

                        </Box>
                    </div>
                    {/* copy right */}
                    <div className={classes.copyRight} onClick={() => window.location.href = isEnglish ? '/e' : '/'}>
                        <p className={classes.copyRightP}>© 2020-2025 Huskycart合同会社</p>
                    </div>
                </div>
            </Hidden>
        </div>
    )
}

export default Footer