import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {removeQuery} from "../utils/func";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: '13px',
        top: '13px',
        color: 'black',
    },
    titleWrapper: {
        color: '#424242',
        paddingLeft: '2rem',
    },
    contentWrapper: {
        maxWidth: '300px',
        paddingBottom: '32px',
    },
    huskyImg: {
        textAlign: 'center',
        // width: 'auto',
        // height: '160 * 1.333983106 px',
        height: '149px',
        // 横:縦 = 3953: 6096 = 1: 1.542119909
        marginBottom: '1rem', // アクションがない時つける
    },
    sekkenImg: {
        textAlign: 'center',
        width: 'auto',
        // height: '160 * 1.333983106 px',
        height: '130px',
        // 横:縦 = 3953: 6096 = 1: 1.542119909
        marginBottom: '1rem',
    },
    layerImage: {
        textAlign: 'center',
        width: '120px',
        height: '185.05438908px',
        position: 'relative',
    },
    overlapImage: {
        height: '90px',
        position: 'absolute',
        bottom: '130px',
        left: '170px',
    },
    link: {
        textDecoration: 'none',
        color: 'blue',
    },
    radius: {
        borderRadius: '30%'
    },
}))

const PopUpDialog = () => {
    const classes = useStyles()

    // const currentUrl = window.location.href
    const currentUrl = removeQuery(window.location.href)
    const isEnglish = currentUrl.slice(-2) === '/e' || currentUrl.slice(-2) === '_e'

    const huskyImg = 'images/under-construction.png'
    const huskyApo = 'images/husky_apo.png'
    const rainImg = 'images/rain_0.png'
    const huskyCart = 'images/cart_husky.png'
    const stopPoll = 'images/stop_poll.png'
    const rothiImg = 'images/dec_rothi.png'
    const huskyHappy = 'images/husky_happy.png'
    const sekken = 'images/sekken.png'
    const inviteHusky = 'images/invite_husky.png'
    const footerImage = 'images/husky_apo.png'
    const chikenImage = 'images/rothi_png.png'
    const freeTrack = 'images/free_track.png'
    const tenPersentOff = 'images/tenPercentOff.png'
    const coupon = 'images/coupon.png'
    const costcoInfo = 'images/costco_info.png'
    const closedImg = 'images/dec_closed.png'
    const discountImg = 'images/discount.png'
    const starImg = 'images/star.png'
    const giaImg = 'images/gia.png'
    const feeUpdate = 'images/fee_update.png'
    const outOfStockChiken = 'images/out_of_stock_chiken.png'
    const freeDeliveryBlue = 'images/free-delivery-blue.png'
    const creditCard = 'images/credit_card.png'
    const moneyBack = 'images/money-back.png'
    const fivePercentOff = "images/fivePercentOff.png"
    const coupon_popup = "images/coupon_popup.png"

    const [open, setOpen] = React.useState(true)
    // const {open, setOpen} = props
    const handleClose = () => {
        setOpen(false)
    }
    // const scrollToAvailableArea = () => {
    //     document.body.scrollTop = document.documentElement.scrollTop = 480
    //     handleClose()
    // }

    const [activeStep, setActiveStep] = useState(0)

    return (
        <div>
            <Dialog
                maxWidth='lg'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{border: '7px solid #afeeee'}}>
                    <IconButton className={classes.closeButton} onClick={() => handleClose()}>
                        <CloseIcon/>
                    </IconButton>

                    <DialogTitle id="alert-dialog-title" className={classes.titleWrapper}>
                        <Typography style={{fontSize: '20px'}}>
                            {isEnglish ? 'NEWS' : '最新情報'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.contentWrapper} style={{paddingTop: "0px"}}>
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'Regarding sales of\nrotisserie chicken\nduring Christmas\n\n'
                                            :
                                            'クリスマス期間中\nロティサリーチキン\n販売について\n\n'
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/guide/christmas-rotisserie-chicken-sale-e/'
                                            :
                                            'https://costco-johokan.com/costco-news/guide/christmas-rotisserie-chicken-sale/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? '＞Click here for detail.' : '＞詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={chikenImage} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'Opening Hours for Christmas and New Year\'s Holidays\n\n■ '
                                            :
                                            'クリスマス・年末年始の\n休業期間について\n\n■ '
                                        }
                                        <a href={isEnglish ?
                                            'https://hubmedia.co.jp/costco/costco-news/huskycart-christmas-new-year-holidays-english/'
                                            :
                                            'https://hubmedia.co.jp/costco/costco-news/huskycart-christmas-new-year-holidays/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? 'Click here for detail.' : '詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                {/*<div style={{textAlign: 'center', marginBottom: '2rem'}}>*/}
                                <div style={{textAlign: 'center'}}>
                                    <img src={closedImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }

                        {activeStep === 2 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'All items 10% off campaign!\nPeriod : Sept. 16 - Sept. 30\n\n■ '
                                            :
                                            '全商品10%OFFセール開催！\n期間 : 9月16日〜9月30日\n\n■ '
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/huskycart-allitems-10off-e/'
                                            :
                                            'https://costco-johokan.com/costco-news/huskycart-allitems-10off/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? 'Click here for detail.' : '詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={tenPersentOff} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }

                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            '[New campaign]\nYour Costco product image\nare being exchanged\nfor discount our coupons!\n\n■ '
                                            :
                                            '【期間限定キャンペーン】\nあなたのコストコ商品画像\nを割引クーポンで買取中！\n\n■ '
                                        }
                                        <a href={isEnglish ?
                                            'https://hubmedia.co.jp/costco/costco-news/value/huskycart-coupon-change-english/#st-toc-h-2'
                                            :
                                            'https://hubmedia.co.jp/costco/costco-news/value/huskycart-coupon-change/#st-toc-h-2'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? 'Click here for detail.' : '詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={coupon_popup} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }

                        {activeStep === 0 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'You can get a discount coupon\naccording to the usage amount!\n\n■ '
                                            :
                                            '現在ご購入金額に応じて\n割引クーポンを還元中です！\n\n■ '
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/what-is-huskycart-e/#st-toc-h-8'
                                            :
                                            'https://costco-johokan.com/costco-news/what-is-huskycart/#st-toc-h-8'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? 'Click here for detail.' : '詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                {/*<div style={{textAlign: 'center', marginBottom: '2rem'}}>*/}
                                <div style={{textAlign: 'center'}}>
                                    <img src={moneyBack} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }

                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'July 14-18, 2023.\nCredit card payment\nunder maintenance.\n\n'
                                            :
                                            '7月14日〜18日の期間中\nクレジットカード決済\nメンテナンス中です。\n\n'
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/huskycart-credit-payment-under-maintenance-english/'
                                            :
                                            'https://costco-johokan.com/costco-news/huskycart-credit-payment-under-maintenance/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? '＞Click here for detail.' : '＞詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center', marginBottom: '30px'}}>

                                    <img src={creditCard} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'Choose cash on delivery as \npayment, discount of 5% of\nthe total product price!\nThe end period is undecided.\n\n＞'
                                            :
                                            'お支払い方法を代金引換にすると\n商品代金の合計5％分が割引に！\nキャンペーン終了日は未定です。\n\n＞'
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/huskycart-5off-sale-e/'
                                            :
                                            'https://costco-johokan.com/costco-news/huskycart-5off-sale/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? 'Click here for detail.' : '詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={fivePercentOff} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <p style={{color: '#424242'}}>
                                        {isEnglish ?
                                            'All items are 10% off sale!\nPeriod : October 17 - 31\n※ Payment method during the period is cash on delivery only\n\n'
                                            :
                                            '全商品10%OFFセール実施中！\n期間 : 10月17日〜10月31日\n※お支払い方法は代金引換のみ\n\n'
                                        }
                                        <a href={isEnglish ?
                                            'https://costco-johokan.com/costco-news/huskycart-allitems-10off-e/'
                                            :
                                            'https://costco-johokan.com/costco-news/huskycart-allitems-10off/'}
                                           target='_blank'
                                           rel="noopener noreferrer"
                                           style={{color: 'blue', textDecoration: 'none'}}>
                                            {isEnglish ? '＞Click here for detail.' : '＞詳細はこちら'}
                                        </a>
                                    </p>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={tenPersentOff} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0', width: '149px'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#promotion2' : 'https://husky-cart.online/#プロモーション2'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'No fee after review\nCheck the procedure'
                                            :
                                            'クチコミで手数料無料\n手順を確認'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={starImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#Function-change' : 'https://husky-cart.online/#機能変更'}
                                        // target='_blank' rel="noopener noreferrer"
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'New function add / change\nClick here for details'
                                            :
                                            '新機能追加・変更\n詳細はこちら'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={giaImg} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                        }
                        {activeStep === 10 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}>
                                    <a href={isEnglish ? 'https://husky-cart.online/#price-revision' : 'https://husky-cart.online/#%E6%96%99%E9%87%91%E6%94%B9%E5%AE%9A'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            // 'MAR. 8th, Fee is free\nClick here'
                                            'Price revision\nInformation'
                                            :
                                            '料金改定の\nお知らせ'}
                                        {/*'3月8日、手数料無料\n詳細はこちら'}*/}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={feeUpdate} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}/>
                                </div>
                            </div>
                            // <div>
                            //     <DialogContentText
                            //         style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}
                            //     >
                            //         <a href={isEnglish ? '/covid_e' : '/covid'} target='_blank'
                            //            style={{color: 'blue', textDecoration: 'none'}}
                            //         >
                            //             {isEnglish ?
                            //                 '4/12 ~ 4/16【Limited edition】\nALL 10%OFF' :
                            //                 '4/12 ~ 4/16【期間限定】\n全商品10%OFF'}
                            //         </a>
                            //
                            //     </DialogContentText>
                            //     <div style={{textAlign: 'center'}}>
                            //         <img src={tenPersentOff} alt="under-construction" className={classes.huskyImg}
                            //              style={{marginTop: '0', marginBottom: '0'}}
                            //         />
                            //     </div>
                            // </div>
                        }

                        {activeStep === 5 &&
                            <div>
                                <DialogContentText
                                    style={{whiteSpace: 'pre-wrap', width: '250px', textAlign: 'center'}}
                                    // style={{whiteSpace: 'pre-wrap', width: '263px'}}
                                >
                                    <a href={isEnglish ? '/covid_e' : '/covid'}
                                       style={{color: 'blue', textDecoration: 'none'}}>
                                        {isEnglish ?
                                            'Covid-19\nInfection Control' :
                                            '新型コロナウイルス\n感染対策について'}
                                    </a>

                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <img src={sekken} alt="under-construction" className={classes.huskyImg}
                                         style={{marginTop: '0', marginBottom: '0'}}
                                        // style={{marginTop: '3rem', marginBottom: '0'}}
                                    />
                                </div>
                            </div>
                        }

                        {/*<DialogActions style={{justifyContent: 'space-between'}}>*/}
                        {/*    <Button*/}
                        {/*        onClick={() => setActiveStep(activeStep - 1)}*/}
                        {/*        color="default"*/}
                        {/*        disabled={activeStep === 0}*/}
                        {/*        variant='contained'>*/}
                        {/*        {isEnglish ? 'back' : '前へ'}*/}
                        {/*    </Button>*/}
                        {/*    <p style={{opacity: '0.54'}}>&lt; {activeStep + 1} / 2 &gt;</p>*/}
                        {/*    <Button*/}
                        {/*        onClick={() => setActiveStep(activeStep + 1)}*/}
                        {/*        color="default"*/}
                        {/*        disabled={activeStep === 1}*/}
                        {/*        variant='contained'>*/}
                        {/*        {isEnglish ? 'next' : '次へ'}*/}
                        {/*    </Button>*/}
                        {/*</DialogActions>*/}
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    )

}

export default PopUpDialog